// eslint-disable-next-line react/prop-types
import { maxWidth } from "../../constant";
import { motion } from "framer-motion";
const Wraper = ({children , ...rest })=>{
    const { style = {} , onClick } = rest

    return <motion.div
        style={{
            width : "100vw",
            height : "100svh",
            margin : "auto",
            maxWidth : `${maxWidth}px`,
            position : "relative",
            // /backgroundColor : "black",
            ...style
        }}

        initial={{
            opacity : 0
        }}

        animate={{
            opacity : 1
        }}

        onClick={onClick}

        
        className="wrapper"
        >
        {children}
    </motion.div>
}


export default Wraper
  