/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 Coach_Signage_1_low.glb 
*/

import React, { useRef , useEffect} from 'react'
import { useGLTF , Html} from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import gsap from 'gsap';


export default function Model(props) {
  const {targetPos , complete1} = props;
  const { viewport } = useThree();
  const { nodes, materials } = useGLTF('/3d/Coach_Signage_1_low.glb');
  const groupRef = useRef();

  useEffect(()=>{
    if(groupRef.current){
      groupRef.current.traverse((obj) => {
        // check all materials
        if(obj.material) {
          
          obj.material.transparent = true
          // flip normals
          obj.material.side = 2
          // obj.alphaTest = 0.5
          obj.material.depthWrite = true
          obj.material.depthTest = true
          obj.material.needsUpdate = true
        }
      })

      if(complete1){
        gsap.fromTo(groupRef.current.position, 
          {x : targetPos.x, y : targetPos.y, z : targetPos.z,
          },
          {
          x : 0, y : 0, z : 5, 
          duration : 2, 
          ease : "power2.inOut",
          onComplete : ()=>{
            window.canStart = true;
            let swipeup = document.getElementById("swipeup");
            let child = document.querySelector("#swipeup .child");
            child.style.pointerEvents = "auto";
            swipeup.style.opacity = 1;
          }
        })

        setTimeout(()=>{
          try{
            if(window.canplay){
              document.querySelector("#Coach-Play-Ipoh-metal-plate-SFX").play()
            }
          }
          catch(e){
              console.log(e);
          }
        },1300)

        gsap.fromTo(groupRef.current.rotation, 
          {x : 0, y : 0, z : 0,
          },
          {
          x : Math.PI * 0.02, y : Math.PI * 2 + Math.PI * 0.1, z : 0, 
          duration : 2,
          onComplete : ()=>{
          }, 
          ease : "power2.inOut",
        })

        gsap.fromTo(groupRef.current.scale, 
          {x : 0.1, y : 0.1, z : 0.1,
          },
          {
          x : 3.2, y : 3.2, z : 3.2, 
          duration : 2, 
          ease : "power2.inOut",
        })
      }
    }
  },[complete1])

  return (
    <group {...props} dispose={null}
      ref={groupRef}
      position={[0,viewport.height * -1,0]}
      name='myObject'
    >
      <mesh geometry={nodes.Neon_Rings_Center.geometry} material={materials['Neon Glow.001']} />
      <mesh geometry={nodes.Curve003.geometry} material={materials['BG.001']} />
      <mesh geometry={nodes.Curve003_1.geometry} material={materials['BG Plain.001']} />
    </group>
  )
}

useGLTF.preload('/3d/Coach_Signage_1_low.glb')