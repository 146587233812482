import { easeInOut, Reorder } from "framer-motion"
import { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { useSuperfan } from "../../index"
import sign from "jwt-encode"
import { useNavigate } from 'react-router-dom';
import { doc , getDoc} from "firebase/firestore";

const Index = ()=>{
    let _items = {
        ticket : {
            title : "VIP-ACCESS",
            button : "Invite",
            image : "/2d/Asset01.png"
        },
        icecream : {
            title : "FREE SOFT-SERVE",
            button : "Claim",
            image : "/2d/Asset02.png"
        },
        coin1 : {
            title : "GASHAPON",
            button : "Claim",
            image : "/2d/Asset03.png"
        },
        coin2 : {
            title : "10% DISCOUNT",
            button : "Claim",
            image : "/2d/Asset04.png"
        },
    }
    const [items , setItems] = useState(_items);


    const {
        uid,
        collection,
        db
    } = useSuperfan();

    useEffect(()=>{
        const fetchData = async () => {
            try{
                const docRef = doc(db, collection, uid);
                const docSnap = await getDoc(docRef);
                const data = docSnap.data();
                document.getElementById("redeemContainer").style.opacity = 1;
                if(data?.redeemPrice){
                    const { redeemPrice } = data;
                    let pre = _items;
                    Object.keys(pre).map((key)=>{
                        if(typeof redeemPrice[key] === "string"){
                            pre[key].isRedeemed = true;
                            localStorage.setItem(key, true);
                        }
                        else{
                            pre[key].isRedeemed = false;
                        }
                    })
                    setItems(pre);
                }
            }
            catch(e){
                console.log(e);
            }
        };
        if(uid){
            fetchData();
        }
    },[uid])
    

    return (
        <div
            id="redeemContainer"
        >
            {
                Object.keys(items).map((key , index)=>{
                    return (
                        <Component
                            index={index}
                            key={key}
                            title={items[key].title}
                            prize={key}
                            button={items[key].button}
                            image={items[key].image}
                            isRedeemed={items[key]?.isRedeemed || false}
                            id={"item-"+index}
                        />
                    )
                })
            }
            <div style = {{height : "5rem"}}/>
        </div>
    )
}

const Component = ({
    index,
    title,
    button,
    image,
    isRedeemed,
    id,
    prize
})=>{
    const nav = useNavigate();
    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.5 + 1,
                duration: 0.5,
            }
        })
    };

    const {
        uid,
        collection,
        db
    } = useSuperfan();

    let isRedeemedLocal = localStorage.getItem(prize) || false;

    useEffect(()=>{
        if(isRedeemedLocal){
            localStorage.removeItem(prize);
        }
    },[isRedeemedLocal])


    return (
        <motion.div
            style={{
                width : "100%",
                height : "7.5rem",
                // backgroundColor : "red",
                borderRadius : "0.75rem",
                marginTop : index === 0 ? "0" : "-0.3rem",
                boxShadow: '1px 1px 20px rgba(0,0,0,60%)',
                // border : "1px solid white",
                display : "flex",
                position: "relative",
                backdropFilter: "blur(5px)",
                WebkitBackdropFilter : "blur(5px)",
                // to black and white
                background : isRedeemedLocal ? "url('/selection_grey.png')" :  "url('/selection.png')",
                backgroundSize : "cover",
                backgroundPosition : "center",
                zIndex : 100 + index,
                position : "relative",
                // backdrop filter 
            }}
            id={id}
            custom={index}
            initial="hidden"
            animate="visible"
            variants={itemVariants}
        >
            <div
                style={{
                    height : "100%",
                    width : "fit-content",
                    margin : "auto",
                    paddingTop : "0.3rem",
                    position : "relative",
                }}
            >
                <img src={image} style={{height : "100%" 
                    , 
                    filter : isRedeemedLocal ? "grayscale(100%)" : "none"
                }} />

                {
                    isRedeemedLocal &&

                    <div
                        className="imgContainer"
                        style={{
                            position : "absolute",
                            left : "50%",
                            top : "50%",
                            transform : "translate(-50%,-50%)",
                            height : "2.5rem",
                            width : "fit-content",
                        }}
                    >
                        <img
                            src="/redeemed.png"
                            style={{height : "100%" , width : "auto"}}
                        />
                    </div>
                }
            </div>
            <div
                style={{
                    height : "100%",
                    width : "50%",
                    display : "flex",
                    flexDirection : "column",
                    justifyContent : "center",
                }}
            >
                <h6 style={{
                    fontSize : "0.75rem",
                }}>{title}</h6>
                <div style={{height : "0.5rem"}}/>
                <button style={{
                    width : "6.5rem", 
                    margin : "0 auto",
                    borderRadius: '20px',
                    padding: '0.6rem 1.4rem',
                    backgroundColor: '#CD450A',
                    border: '2px solid #DEA42B',
                    fontSize: '0.7rem',
                    filter : isRedeemedLocal ? "grayscale(100%)" : "none",
                    fontSize : "0.72rem",
                }}
                onClick={()=>{
                    if(isRedeemedLocal){
                        return;
                    }
                    const secret = 'secret';
                    let json = {
                        prize : prize,
                        uid : uid,
                        collection : collection,
                        exp : Date.now() + 1000 * 60
                    }

                    const jwt = sign(json, secret);
                    nav("/qr?token="+jwt);
                }}
                >
                    {isRedeemedLocal ? "Redeemed" : button}
                </button>
            </div>     
        </motion.div>
    )
}

export default Index;