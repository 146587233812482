import { Button2 } from "./index"
import { useNavigate } from "react-router-dom"
import Wraper from "./wraper";
const Index = ({
    setActive
})=>{
    const nav = useNavigate();
    return (
        <Wraper
            style={{
                width : "100svw",
                height : "100svh",
                position : "absolute",
                top : 0,
                left : 0,
                display : "flex",
                justifyContent : "center",
                alignItems : "center",
                backgroundColor : "rgba(0,0,0,0.3)",
            }}
        >
            <div
                style={{
                    backgroundColor : "#DEA42B",
                    width : "80svw",
                    height : "80svh",
                    position : "relative",
                }}
            >
                <div
                    className="imgContainer-h"
                    style={{
                        position : "absolute",
                        height : "80%",
                        zIndex : 0,
                        left : 0,
                    }}
                >
                    <img src='2d/left.webp' className='logo'/>
                </div>

                <div
                    className="imgContainer-h"
                    style={{
                        position : "absolute",
                        height : "90%",
                        zIndex : 0,
                        right : 0,
                        bottom : 0,
                    }}
                >
                    <img src='2d/right.webp' className='logo'/>
                </div>



                <div className="block" style={{
                    height : "11%"
                }}/>

                <h2>
                    DON’T FRET IF<br/>
                    YOU’RE NOT THERE <br/>(YET)!
                </h2>

                <div className="block" style={{
                    height : "5%"
                }}/>


                <h6>LOCATE US</h6>
                <div className="block" style={{
                    height : "2%"
                }}/>
                <p
                    style={{
                        lineHeight : "1.2",
                        fontSize : "0.65rem",
                    }}
                >
                    Lot No. LF-36 & LF-37 Lakefront<br/>
                    Silverlakes Village Outlet<br/>
                    1, Persiaran Silverlakes, Kampung <br/>
                    Belangkor, 31000 Batu Gajah, Perak<br/>
                    <br/>
                    <span>
                        Telephone: +6053668283<br/>
                        WhatsApp: +60125308269<br/>
                    </span>


                    <div className="block" style={{
                        height : "2svh"
                    }}/>

                    Open 10am–10pm, every day of the week.
                </p>

                <div className="block" style={{
                    height : "5%"
                }}/>


                <h6>EXPLORE THE WILDS OF YOUR <br/>IMAGINATION</h6>
                <div className="block" style={{
                    height : "2%"
                }}/>
                <p
                    style={{
                        lineHeight : "1.2",
                        fontSize : "0.65rem",
                    }}
                >
                    With exciting offerings such as<br/>
                    Malaysia’s first Coach Coffee Shop, you<br/> wouldn’t want to miss out.
                </p>

                <div className="block" style={{
                    height : "6%"
                }}/>


                <Button2
                        text="LEARN MORE"
                        onClick={()=>{
                            window.open("https://malaysia.coach.com/coach-play-ipoh" , "_blank")
                        }}

                        _id={"learnmore1"}

                        style = {{
                            width : "10rem",
                            paddingTop : "0.8rem",
                            paddingBottom : "0.65rem",
                            fontSize : "0.7rem",
                        }}
                />

                <div style={{
                    height : "0.6rem"
                }}/>

                <Button2
                        text="BACK"
                        onClick={()=>{
                            setActive(null)
                        }}

                        _id={"back1"}

                        style = {{
                            width : "10rem",
                            paddingTop : "0.8rem",
                            paddingBottom : "0.65rem",
                            fontSize : "0.7rem",
                        }}
                />
            </div>
        </Wraper>
    )
}


export default Index;