import QrScanner from "qr-scanner";
import { useState , useRef , useEffect, useMemo } from 'react';
import { useLocation } from "react-router-dom";
const QrReader = () => {
  // QR States
  const location = useLocation();
  const scanner = useRef();
  const videoEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [content , setContent] = useState(null);

  useMemo(()=>{
    if(content && window.isFullyLoadedMM){
      let myUrl = new URL(content);
      let params = new URLSearchParams(myUrl.search);
      let item = params.get('item');
      switch(item){
        case "1":
          if(window.canplay){
            document.querySelector("#Scan-Explosion-Universal-SFX").play().then(()=>{
              window.play1();
            })
            .catch((err)=>{
              window.play1();
            })
          }
          else{
            window.play1();
          }
          
          localStorage.setItem("item1", true);
          break;
        case "2":
          if(window.canplay){
            document.querySelector("#Scan-Explosion-Universal-SFX").play().then(()=>{
              window.play2();
            })
            .catch((err)=>{
              window.play2();
            })
          }
          else{
            window.play2();
          }          
          localStorage.setItem("item2", true);
          break;
        case "3":
          if(window.canplay){
            document.querySelector("#Scan-Explosion-Universal-SFX").play().then(()=>{
              window.play3();
            })
            .catch((err)=>{
              window.play3();
            })
          }
          else{
            window.play3();
          }          
          localStorage.setItem("item3", true);
          break;

        default:
          break;
      }
    }
  },[content]);

  const onScanSuccess = (result) => {
    // check current page
    if(window.location.pathname === "/page4"){
      if(result.data !== content && !window?.isplaying){
        setContent(result.data);
      }
    }
  };

  // Fail
  const onScanFail = (err) => {
    
  };

  useEffect(() => {
    // check divice is ios or android


    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: false,
        highlightCodeOutline: false,
      });

      scanner?.current
        ?.start()
        .then(() => {
          if(!window.pauseFromUser){
            window.canplay = true;
            window.setIsMuteddd();
            let audio = document.querySelector("#audio");
            if(audio){
              audio.play();
            }
          }
          setQrOn(true);
          setTimeout(() => {
            try{
              let bgcontainer = document.getElementById("bg-video-container");
              bgcontainer.style.opacity = "0";
              let backIcon = document.getElementById("backIcon");
              backIcon.style.zIndex = "2";
            }
            catch(err){
            }          
          }, 600); 
        })
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  return (
    <div className="qr-reader">
      <video ref={videoEl} id="video" style={{
        opacity: "1",
        transition: "0.5s",
      }}></video>
    </div>
  );
}

export default QrReader;
