import * as React from "react";
import {
  createContext,
  useContext,
  useState,
} from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously , onAuthStateChanged , signInWithCustomToken , signInWithPopup } from "firebase/auth";

import { Toaster } from "react-hot-toast";
import { getFunctions , httpsCallable } from 'firebase/functions';
import { getGeo } from "./help";
import { doc , getDoc , getFirestore , setDoc} from 'firebase/firestore';
import axios from "axios";
import { GoogleAuthProvider } from "firebase/auth";
import PopupProgress from "./sso/component/popupProgress/index"

const SuperfanContext = createContext();



export function SuperfanProvider({ ...props }) {
  const { firebaseConfig, collection } = props;
  const firebaseApp = initializeApp(firebaseConfig);
  const functions = getFunctions(firebaseApp);
  const auth = getAuth();
  const db = getFirestore(firebaseApp);
  const provider = new GoogleAuthProvider();
  const [uid , setUid] = useState(null);


  const checkIsAnonymous = async(uid) => {
    if(!uid) return;
    const _checkIsAnonymous = httpsCallable(functions, "isAnonymous");
    let ress = await _checkIsAnonymous({ uid: uid })
    if(ress?.data.length === 0){
      localStorage.setItem("isAnonymous", true)
      return true;
    }
    else{
      localStorage.setItem("isAnonymous", false)
      return false;
    }
  }

  const signIn = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@") ? "email" : "phone";
    const data = {
      type: type,
      measure: localStorage.getItem("emailOrMobile"),
      anonymousID: uid,
      collection: collection,
    };

    const signIn = httpsCallable(functions, "signIn");
    const response = await signIn(data);

    let token = response.data?.token;
    if(token){
      signInWithCustomToken(auth, token);
    }

    setUid(response.data?.uid);
    const snapshot = await getDoc(doc(db, "superfanUser", response.data?.uid));
    if(snapshot.exists()){
      if(!snapshot.data().displayName){
        return true;
      }
      else{
        return false;
      }
    }
    return true;
  };

  const twillioOTP = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@") ? "email" : "phone";
    return new Promise(async(resolve , reject)=>{
      try{
        switch(type){
          case "phone":
            try{
              let verifywhatsapp = httpsCallable(functions, "verifywhatsapp");
              let res1 = await verifywhatsapp({ type: type, phone: localStorage.getItem("emailOrMobile") });
              resolve(res1);
            }
            catch(e){
              reject("Invalid Phone Number");
            }
            break;
          case "email":
            try{
              let verifyEmail = httpsCallable(functions, "verifyEmail");
              let res = await verifyEmail({ type: type, email: localStorage.getItem("emailOrMobile") });
              resolve(res);
            }
            catch(e){
              reject("Invalid Email");
            }
            break;
        }
      }
      catch(e){
        reject(e);
      }
    });
  };

  const checkOTP = async (measure , code ) => {
    let checkOtppp = httpsCallable(functions, "checkOtppp");
    let res = await checkOtppp({ measure: measure, code: code });
    return res;
  }

  const sendEmailMgs = async({
    email,
    title1,
    link,
    date
  })=>{
    let send = httpsCallable(functions, "sendEmail");
    await send({
      email: email,
      title1: title1,
      link: link,
      date: date,
    });
  }

  const addDisplayName = async (displayName) => {
    if(!uid) return;
    setDoc(doc(db, "superfanUser", uid), {
      displayName: displayName,
    }, {
      merge: true,
    });
  }

  const checkLeftInfo = async() => {
    if(!uid) return;
    const snapshot = await getDoc(doc(db, "superfanUser", uid));
    if(snapshot.exists()){
      // if left displayName
      if(!snapshot.data().displayName){
        return true;
      }
      else{
        return false;
      }
    }
    return true;
  }

  const googleSignIn = async (nav) => {
    try{
      let res = await signInWithPopup(auth, provider);
      let uid = res.user.uid;
      if(uid){
        setUid(uid);
        checkLeftInfo(uid).then((res)=>{
          if(res){
            nav("/collect-info");
          }
          else{
            nav("/redeem");
          }
        }).catch((e)=>{});
      }
    }
    catch(e){
      console.log(e);
      return "error"
    }
  }

  const signOut = async () => {
    return new Promise(async(resolve , reject)=>{
      try{
        await auth.signOut();
        resolve(true);
      }
      catch(e){
        reject(e);
      }
    });
  }

  const twillioOTPSMS_EMAIL = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@") ? "email" : "phone";
    return new Promise(async(resolve , reject)=>{
      try{
        switch(type){
          case "phone":
            try{
              let verifywhatsapp = httpsCallable(functions, "verifysms");
              let res1 = await verifywhatsapp({ type: type, phone: localStorage.getItem("emailOrMobile") });
              resolve(res1);
            }
            catch(e){
              reject("Invalid Phone Number");
            }
            break;
          case "email":
            try{
              let verifyEmail = httpsCallable(functions, "verifyEmail");
              let res = await verifyEmail({ type: type, email: localStorage.getItem("emailOrMobile") });
              resolve(res);
            }
            catch(e){
              reject("Invalid Email");
            }
            break;
        }
      }
      catch(e){
        reject(e);
      }
    });
  };

  const init = async()=>{
    if(!uid) return;
    const _docRef = doc(db, collection, uid);
    const snapshot = await getDoc(_docRef);

    checkIsAnonymous(uid);
    
    return new Promise(async(resolve , reject)=>{
      if(snapshot.exists()){
        resolve(true);
      }else{
        const [brand , region , campaign] = collection.split("-");
        const details = {
          brand,
          region,
          campaign,
          uuid: uid,
          createTime: new Date().toJSON(),
          redeemTime: null,
          updateTime: null, // for bigquery
          userAgent: navigator.userAgent,
          replay: 0,
          timespent: 0,
          score: 0,
          events: {},
          gameDatas: {},
          dataCollection: null,
        }

        try{
          let data = await getGeo();
          setDoc(doc(db,collection,uid), {
              ...details,
              geoData : JSON.stringify(data?.geoData || {}),
            },{
              merge: true
            }
          ).then(()=>{
            resolve(true);
          });
        }
        catch(e){
          reject(e);
        }  
      }
    });
  }


  React.useEffect(() => {
    init();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUid(uid);
      } else {
        signInAnonymously(auth , (user) => {
          setUid(user.uid);
        });  
      }
    });
  

    window.test = ()=>{test()}

    window.signOut = ()=>{
      signOut();
    }
  }, [uid]);

  return (
    <SuperfanContext.Provider   
      value={{
        signIn,
        twillioOTP,
        twillioOTPSMS_EMAIL,
        addDisplayName,
        uid,
        db,
        checkIsAnonymous,
        checkLeftInfo,
        googleSignIn,
        collection,
        sendEmailMgs,
        checkOTP
      }}
    >
      {props.children}
      <Toaster/>
      <PopupProgress/>
    </SuperfanContext.Provider>
  );
}



export const useSuperfan = () => useContext(SuperfanContext);