import * as THREE from 'three'

let _vilocity = new THREE.Vector3(0,0.12,0);
let _gravity = new THREE.Vector3(0,-0.00098,0);
let _position = new THREE.Vector3(0,-5,0);
let _initAcc = new THREE.Vector3(0,0,0);
export {
    _vilocity,
    _gravity,
    _position,
    _initAcc
}