import SvgComponent from './svglaoding';
import { motion } from 'framer-motion';
import gsap from 'gsap';
import { useSuperfan } from '../../../index';
import { useMemo, useState } from 'react';
import { getDoc } from 'firebase/firestore';
import { doc } from 'firebase/firestore';
import LottieJSON from "./Composition.json";
import Lottie from "lottie-react";
import Gift from "./gift.png";
import ConfettiJSON from "./confetti.json"
import { useNavigate } from 'react-router-dom';

const Index  = ()=> {
    const [swtich , setSwitch] = useState(false);
    const nav = useNavigate();
    return (
        <section
            style={{
                position : "fixed",
                width : "100svw",
                height : "100svh",
                zIndex : 9999,
                overflow : "hidden",
                pointerEvents : "none",
            }}
            id='popupProgressContainer'

            onClick={(e)=>{
                e.stopPropagation();
                e.preventDefault();

                let popUpProgress = document.getElementById("popupProgressContainer");
                let isBottom = popUpProgress.style.bottom === "-100%" ? true : false;
                
                gsap.to(popUpProgress,{
                    duration : 1,
                    bottom : isBottom ? "0%" : "-100%",
                    pointerEvents : isBottom ? "all" : "none",
                    ease : "power2.inOut",
                })

                nav('/page5')
            }}
        >
            <motion.div
                style={{
                    height : "65svh",
                    width : "95svw",
                    backgroundColor : "black",
                    position : "absolute",
                    left : "50%",
                    transform : "translate(-50%,0)",
                    bottom : "-100%",
                    borderRadius : "0.5rem 0.5rem 0 0",
                    padding : "0 2rem",
                }}
                id='popupProgress'
                onClick={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                {
                    swtich ? <Inner2/> : <Price setSwitch = {setSwitch}/>
                }
            </motion.div>
        </section>
    )
}

const Price = ({
    setSwitch
})=>{
    const nav = useNavigate();
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                position : "relative",
            }}
        >
            <div className='block' style={{height : "5%"}}/>

            <h2 style={{
                textAlign : "left",
                color : "white",
                fontFamily : "Poppins-Medium",
            }}>
                Congratulations!
            </h2>
            
            <div className='block' style={{height : "5%"}}/>

            <p
                style={{
                    textAlign: "left",
                    fontFamily : "Poppins-Regular",
                    fontWeight : 100,
                }}
            >
                You’ve completed the experience. <br/>
                Check your WhatsApp for a <br/>
                redemption link.
            </p>

            <div className='block' style={{height : "7%"}}/>
            
            <div
                className='imgContainer-h'
                style={{
                    height : "35%",
                    width : "fit-content",
                    margin : "0 auto",
                    position : "relative",
                }}
            >
                <img src = {Gift}/>

                <div
                    className='lottieContainer'
                    style={{
                        position : "absolute",
                        zIndex : -1,
                        width : "100%",
                        height : "100%",
                        top : "70%",
                        transform : "translate(0%,-50%) scale(2.2)",
                    }}
                >
                    <Lottie animationData={ConfettiJSON} loop = {true}/>
                </div>
                
            </div>
            <div className='block' style={{height : "10%"}}/>

            <h5 style={{textAlign : "center" , textDecoration : "underline"}}
                onClick={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    setSwitch((pre)=>{return !pre});
                }}
            >Tap to see your status</h5>
        </div>
    )
}

export const Inner = ({
})=>{
    const {uid , db} = useSuperfan();
    const [displayName, setDisplayName] = useState("");
    const nav = useNavigate();

    useMemo(async()=>{
        if(uid){
            let docRef = doc(db, "superfanUser" ,uid);
            let _ = await getDoc(docRef);
            setDisplayName(_.data()?.displayName || "");  
        }
    },[uid]);

    useMemo(()=>{
        if(displayName !== "") {
            let before = 0;
            let after = 26;
            let all = document.querySelectorAll(".controlsvg")
            setTimeout(()=>{
                if(all){
                    for(let i = before ; i < after ; i++){
                        let index = i % all.length;
                        all[index].style.transitionDelay = `${(i) * 0.02}s`;
                        all[index].style.fill = "#00BF63"
                    }  
                }
            },900)

            setTimeout(()=>{
                nav("/page3")
            },3500)
        }
    },[displayName]);


    return (
        <motion.div
            style={{
                height: "100%",
                width: "100%",
                position : "relative",
            }}

            initial={{
                opacity : 0,
            }}

            animate={{
                opacity : displayName === "" ? 0  : 1,
                transition : {
                    duration : 1,
                },
            }}
        >
            <div className='block' style={{height : "10%"}}/>

            <h2 style={{
                textAlign : "left",
                color : "white",
                fontFamily : "Poppins-Medium",
            }}>
                Welcome back, {displayName}
            </h2>
            
            <div className='block' style={{height : "3%"}}/>
            <p
                style={{
                    textAlign: "left",
                    fontFamily : "Poppins-Regular",
                    fontWeight : 100,
                }}
            >
            You’re almost superfan status with<br/>
            Coach! 
            </p>
            <div className='block' style={{height : "5%"}}/>

            <div
            style={{
                height: "45%",
                width : "fit-content",
                margin : "0 auto",
                maxHeight : "70svw"
            }}
                className='svgContainer-h'
            >   
                <div
                    className='lottieContainer'
                    style={{
                        position : "absolute",
                        zIndex : -1,
                        width : "100%",
                        height : "100%",
                        left : "-15%",
                        top : "30%",
                        transform : "scale(1)",
                    }}
                >
                    <Lottie animationData={LottieJSON} loop = {true}/>
                </div>
                <SvgComponent/>
            </div>
        </motion.div>
    )
}

export const Inner2 = ()=>{
    const {uid , db} = useSuperfan();
    const [displayName, setDisplayName] = useState("");

    useMemo(async()=>{
        if(uid){
            let docRef = doc(db, "superfanUser" ,uid);
            let _ = await getDoc(docRef);
            setDisplayName(_.data()?.displayName || "");  
        }
    },[uid]);

    useMemo(()=>{
        if(displayName !== "") {
            let before = 0;
            let after = 66;
            let all = document.querySelectorAll(".controlsvg")
            setTimeout(()=>{
                if(all){
                    for(let i = before ; i < after ; i++){
                        let index = i % all.length;
                        all[index].style.transitionDelay = `${(i) * 0.02}s`;
                        all[index].style.fill = "#00BF63"
                    }  
                }
            },900)
        }
    },[displayName]);


    return (
        <motion.div
            style={{
                height: "100%",
                width: "100%",
                position : "relative",
            }}

            initial={{
                opacity : 0,
            }}

            animate={{
                opacity : displayName === "" ? 0  : 1,
                transition : {
                    duration : 1,
                },
            }}
        >
            <div className='block' style={{height : "10%"}}/>

            <h2 style={{
                textAlign : "left",
                color : "white",
                fontFamily : "Poppins-Medium",
            }}>
                Awesome, Almost there!
            </h2>
            
            <div className='block' style={{height : "3%"}}/>
            <p
                style={{
                    textAlign: "left",
                    fontFamily : "Poppins-Regular",
                    fontWeight : 100,
                }}
            >
            Engage more with COACH <br/>to level up.
            </p>
            <div className='block' style={{height : "8%"}}/>

            <div
            style={{
                height: "45%",
                width : "fit-content",
                margin : "0 auto",
                maxHeight : "70svw"
            }}
                className='svgContainer-h'
            >   
                <div
                    className='lottieContainer'
                    style={{
                        position : "absolute",
                        zIndex : -1,
                        width : "100%",
                        height : "100%",
                        left : "-10%",
                        top : "30%",
                        transform : "scale(1.1)",
                    }}
                >
                    <Lottie animationData={LottieJSON} loop = {true}/>
                </div>
                <SvgComponent/>
            </div>
        </motion.div>
    )
}


export default Index;
  