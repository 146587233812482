const BgVideo = () => {
    return (
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          zIndex: "-2",
          transition : "1s",
          overflow: "hidden" // Prevents video from overflowing the container
        }}
        id='bg-video-container'
      >
        <video 
          style={{
            width: "100%", // Video takes up full width of the container
            height: "100%", // Video takes up full height of the container
            objectFit: "cover", // Video maintains aspect ratio and covers the whole container
            transition : "1s"
          }}
          autoPlay
          playsInline
          muted 
          loop
          onPlay={() => {
            window.bgVideoOK = true;
          }}
          id="myVideo"
        >
          <source src="/LP_Vid_2_low.mp4" type="video/mp4"/>
        </video>
      </div>
    )
}

export default BgVideo;