import { motion } from "framer-motion"
import QRCode from "react-qr-code";
import Insta from "./svgs/insta"
import Ytube from "./svgs/ytube"
import { useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc , collection, getDoc, query, setDoc} from "firebase/firestore";
import { useSuperfan } from "../../index"
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

// random 5 digit number mixed with alphabets
const randomToken = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const Qr = () => {
    const { db } = useSuperfan();
    const nav = useNavigate();

    const checkCollect = async(decoded) => {
        if(decoded.collection && decoded.uid && decoded.prize){
            const docRef = doc(db, decoded.collection, decoded.uid);
            let datasnap = await getDoc(docRef);
            if(datasnap.exists()){
                const data = datasnap.data();
                if(data.redeemPrice && data.redeemPrice[decoded.prize]){
                    nav('/redeem')
                    localStorage.setItem(decoded.prize, true);
                }else{
                }
            }
        }
    }

    const [token , setToken] = useState(false);


    useEffect(()=>{
        const query = new URLSearchParams(window.location.search);
        const _token = query.get('token');
        if(_token){
            let ranUID = randomToken();
            let docRef = doc(db, "simplifyJWT", ranUID);
            setDoc(docRef, {
                token : _token
            })
            setToken(ranUID)

            const decoded = jwtDecode(_token);

            const interval = setInterval(() => {
                checkCollect(decoded)
            },2000);

            return () => clearInterval(interval);
        }
    },[])
    
    return (
        <div
            style={{
                position : "absolute",
                width : "90svw",
                height : "145svw",
                maxWidth : "43rem",
                maxHeight : "60rem",
                top : "50%",
                left : "50%",
                transform : "translate(-50% , -50%)",
                zIndex : "100",
                opacity : "1",
                pointerEvents : "none",
                className : "qr-container",
                
            }}
        >
                <motion.div initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1}}
                    transition={{duration: 1}}
                    style={{
                    background: 'black',
                    height: '100%',
                    width: '100%',
                    borderRadius: '15px',
                }} className="superfanQR"> 

                <div className='block' style={{height: '10%'}}/>

                <h1 style={{fontSize: '1.8rem' , 
                    color: 'white',
                    textAlign: 'left',
                    margin: '0 10%'}}>SUPERFAN™</h1>

                <div style={{height: '1.5%'}}/>

                <p style={{
                    fontSize: '0.7rem' , 
                    fontWeight: '500',
                    lineHeight: '1',
                    textAlign: 'left',
                    margin: '0 10%'}}>Please present this QR code to<br/>our registration staff to scan.</p>

                <div style={{height: '8%'}}/>

                <div
                    style={{
                        height : "40%",
                        maxHeight : "20rem",
                        position : "relative"
                    }}
                >
                    {token && (
                        <QRCode title="QR GENERATOR" value={token} 
                        style={{
                            margin: 'auto', 
                            border: ' 20px white solid', 
                            background: 'white',
                            height: "38svh",
                            width: "38svh",
                            maxWidth: "16rem",
                            maxHeight: "16rem",
                        }}/>
                    )}       
                </div>

                <div style ={{height : "12%"}}/>

                <div className='block' style={{
                    borderBottom: '3px #00bf63 solid',
                    margin: '0 10%',
                }}/>

                <div style={{height : "3%"}} className="block"/>
                <Footer/>
            </motion.div>
        </div>
    )
}


const Footer = () => {
    return (
        
        <div style={{
            display: 'flex',
            margin: '1.8% 10%',
            justifyContent : "space-between"
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left'
            }}>
                <p style={{margin: '0', 
                    fontSize: '1rem', 
                    fontWeight: '800'}}>SUPERFAN™</p>

                <p style={{
                    fontSize: '0.5rem', 
                    whiteSpace: 'nowrap',  
                    fontWeight: '500',
                    margin: '0'
                    }}>® All rights reserved by <a>SUPERFAN</a>.</p>
            </div>
            
            <div
                style={{display : "flex"}}
            >
                <div 
                    className="svgContainer" 
                    style={{
                        backgroundColor: '#00bf63', 
                        borderRadius: '50%',
                        border: '5px #00bf63 solid',
                        margin: '0 2%',
                        width : "1.8rem",
                        height : "1.8rem",
                        display : "flex",
                        justifyContent : "center",
                        alignItems : "center",
                        overflow : "hidden",
                        padding : "1px"
                    }}
                >
                    <Insta/>
                </div> 
                <div style={{width : "0.5rem"}}/>
                <div className="svgContainer"                    
                    style={{
                        backgroundColor: '#00bf63', 
                        borderRadius: '50%',
                        border: '5px #00bf63 solid',
                        margin: '0 2%',
                        width : "1.8rem",
                        height : "1.8rem",
                        display : "flex",
                        justifyContent : "center",
                        alignItems : "center",
                        overflow : "hidden"
                    }}
                >
                    <Ytube/>
                </div> 
            </div>
        </div>
    )
}

export default Qr