import { Button2 } from "./index"
import { useNavigate } from "react-router-dom"
import Wraper from "./wraper";
import { useSuperfan } from "../../superfancontext";
const Index = ({
    setActive
})=>{
    const nav = useNavigate();
    const { checkIsAnonymous} = useSuperfan()
    return (
        <Wraper
            style={{
                position : "absolute",
                top : 0,
                left : 0,
                display : "flex",
                justifyContent : "center",
                alignItems : "center",
                backgroundColor : "rgba(0,0,0,0.3)",
            }}
        >
            <div
                style={{
                    backgroundColor : "#DEA42B",
                    width : "20rem",
                    padding : "2rem 0",
                    borderRadius : "0.8rem",
                }}
            >
                <h4 style={{lineHeight : "1.2"}}>Are you at<br/>Coach Play Ipoh?</h4>

                <div className="block" style={{
                    height : "0.8rem"
                }}/>
                <p
                    style={{
                        textAlign : "center",
                        lineHeight : "1.2",
                    }}
                >
                    To participate in our game, <br/>you’ll have to
                    be here in person!
                </p>

                <div className="block" style={{
                    height : "1.8rem"
                }}/>

                <div
                    style={{
                        display : "flex",
                        justifyContent : "center",
                        padding : "0 1rem"
                    }}
                >
                    <Button2
                        text="NOT YET"
                        style={{margin : "none" , fontSize : "0.9rem"}}
                        onClick={()=>{
                            setActive(2)
                        }}
                        _id={"notyet"}
                    />
                    <div className="block" style={{width : "1.5rem"}}/>
                    <Button2
                        text="YES"
                        style={{margin : "none" , fontSize : "0.9rem"}}
                        _id={"yes"}
                        onClick={()=>{
                            nav("/page3");
                        }}
                    />
                </div>
            </div>
        </Wraper>
    )
}


export default Index;