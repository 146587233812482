import { set } from "firebase/database";
import * as React from "react"

const SvgComponent = (props) => {
  let initColor = "#cfd1d3"

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      id="Layer_1"
      x={0}
      y={0}
      style={{
        enableBackground: "new 0 0 500 500",
        height: "100%",
      }}
      viewBox="0 0 500 500"
      {...props}
    >
      <style>{`.st0{fill:${initColor};}`}{".st1{fill:#00BF63}"}</style>

      <polygon className="st0 controlsvg" points="255.3,34.1 244.7,34.1 244,4.2 256,4.2 "/>
      <polygon className="st0 controlsvg" points="269.5,35 259,34.3 260.3,4.3 272.2,5.1 "/>
      <polygon className="st0 controlsvg" points="283.7,36.7 273.2,35.3 276.5,5.5 288.4,7.1 "/>
      <polygon className="st0 controlsvg" points="297.7,39.4 287.4,37.3 292.6,7.8 304.3,10.2 "/>
      <polygon className="st0 controlsvg" points="311.5,43 301.4,40.3 308.5,11.2 320,14.3 "/>
      <polygon className="st0 controlsvg" points="325.1,47.5 315.1,44.1 324.1,15.6 335.5,19.4 "/>
      <polygon className="st0 controlsvg" points="338.3,52.9 328.6,48.9 339.5,21 350.5,25.6 "/>
      <polygon className="st0 controlsvg" points="351.1,59.2 341.7,54.5 354.4,27.4 365.1,32.7 "/>
      <polygon className="st0 controlsvg" points="363.5,66.3 354.4,61 368.9,34.8 379.2,40.8 "/>
      <polygon className="st0 controlsvg" points="375.4,74.2 366.7,68.3 382.9,43.1 392.8,49.8 "/>
      <polygon className="st0 controlsvg" points="386.7,82.9 378.4,76.4 396.2,52.3 405.7,59.7 "/>
      <polygon className="st0 controlsvg" points="397.5,92.3 389.6,85.3 409,62.4 417.9,70.4 "/>
      <polygon className="st0 controlsvg" points="407.6,102.4 400.2,94.9 421,73.3 429.4,81.9 "/>
      <polygon className="st0 controlsvg" points="417,113.1 410.1,105.1 432.3,85 440.1,94.1 "/>
      <polygon className="st0 controlsvg" points="425.7,124.4 419.3,116 442.8,97.4 450,107 "/>
      <polygon className="st0 controlsvg" points="433.6,136.3 427.8,127.5 452.5,110.5 459,120.5 "/>
      <polygon className="st0 controlsvg" points="440.7,148.7 435.5,139.5 461.3,124.2 467.1,134.6 "/>
      <polygon className="st0 controlsvg" points="447,161.5 442.4,152 469.1,138.4 474.3,149.2 "/>
      <polygon className="st0 controlsvg" points="452.4,174.8 448.5,165 476,153.2 480.5,164.3 "/>
      <polygon className="st0 controlsvg" points="456.9,188.3 453.6,178.3 481.9,168.3 485.6,179.7 "/>
      <polygon className="st0 controlsvg" points="460.5,202.1 457.9,191.9 486.8,183.8 489.8,195.4 "/>
      <polygon className="st0 controlsvg" points="463.2,216.1 461.3,205.7 490.7,199.6 492.8,211.4 "/>
      <polygon className="st0 controlsvg" points="465,230.3 463.8,219.8 493.5,215.6 494.8,227.5 "/>
      <polygon className="st0 controlsvg" points="465.8,244.5 465.3,234 495.2,231.8 495.8,243.8 "/>
      <polygon className="st0 controlsvg" points="465.7,258.8 465.9,248.3 495.9,248.1 495.7,260 "/>
      <polygon className="st0 controlsvg" points="464.7,273 465.5,262.5 495.5,264.3 494.5,276.2 "/>
      <polygon className="st0 controlsvg" points="462.7,287.2 464.2,276.8 494,280.5 492.2,292.3 "/>
      <polygon className="st0 controlsvg" points="459.7,301.2 462,290.9 491.4,296.5 488.9,308.2 "/>
      <polygon className="st0 controlsvg" points="455.9,314.9 458.8,304.8 487.8,312.4 484.5,323.9 "/>
      <polygon className="st0 controlsvg" points="451.2,328.4 454.8,318.5 483.2,328 479.1,339.2 "/>
      <polygon className="st0 controlsvg" points="445.5,341.5 449.8,331.8 477.5,343.2 472.7,354.2 "/>
      <polygon className="st0 controlsvg" points="439.1,354.2 443.9,344.9 470.8,358 465.3,368.7 "/>
      <polygon className="st0 controlsvg" points="431.8,366.5 437.2,357.5 463.2,372.4 457,382.6 "/>
      <polygon className="st0 controlsvg" points="423.7,378.2 429.7,369.6 454.6,386.2 447.8,396 "/>
      <polygon className="st0 controlsvg" points="414.8,389.4 421.4,381.2 445.2,399.5 437.7,408.8 "/>
      <polygon className="st0 controlsvg" points="405.2,400 412.4,392.3 434.9,412 426.8,420.9 "/>
      <polygon className="st0 controlsvg" points="395,410 402.6,402.7 423.8,423.9 415.1,432.2 "/>
      <polygon className="st0 controlsvg" points="384.1,419.2 392.2,412.4 411.9,435 402.7,442.7 "/>
      <polygon className="st0 controlsvg" points="372.6,427.7 381.1,421.5 399.3,445.3 389.6,452.3 "/>
      <polygon className="st0 controlsvg" points="360.6,435.4 369.5,429.8 386.1,454.8 376,461.1 "/>
      <polygon className="st0 controlsvg" points="348.1,442.3 357.4,437.3 372.2,463.3 361.7,469 "/>
      <polygon className="st0 controlsvg" points="335.2,448.4 344.8,444 357.9,470.9 347,475.9 "/>
      <polygon className="st0 controlsvg" points="321.9,453.6 331.7,449.8 343,477.5 331.9,481.8 "/>
      <polygon className="st0 controlsvg" points="308.2,457.9 318.3,454.8 327.8,483.2 316.4,486.7 "/>
      <polygon className="st0 controlsvg" points="294.4,461.3 304.6,458.8 312.2,487.8 300.6,490.6 "/>
      <polygon className="st0 controlsvg" points="280.3,463.7 290.7,462 296.4,491.4 284.5,493.4 "/>
      <polygon className="st0 controlsvg" points="266.1,465.3 276.6,464.2 280.3,494 268.4,495.1 "/>
      <polygon className="st0 controlsvg" points="251.9,465.9 262.4,465.5 264.1,495.4 252.1,495.8 "/>
      <polygon className="st0 controlsvg" points="237.6,465.5 248.1,465.9 247.9,495.8 235.9,495.4 "/>
      <polygon className="st0 controlsvg" points="223.4,464.2 233.9,465.3 231.6,495.1 219.7,494 "/>
      <polygon className="st0 controlsvg" points="209.3,462 219.7,463.7 215.4,493.4 203.6,491.4 "/>
      <polygon className="st0 controlsvg" points="195.4,458.8 205.6,461.3 199.4,490.6 187.8,487.8 "/>
      <polygon className="st0 controlsvg" points="181.7,454.8 191.8,457.9 183.6,486.7 172.2,483.2 "/>
      <polygon className="st0 controlsvg" points="168.3,449.8 178.1,453.6 168.1,481.8 156.9,477.5 "/>
      <polygon className="st0 controlsvg" points="155.2,444 164.8,448.4 153,475.9 142.1,470.9 "/>
      <polygon className="st0 controlsvg" points="142.6,437.3 151.9,442.3 138.3,469 127.8,463.3 "/>
      <polygon className="st0 controlsvg" points="130.5,429.8 139.4,435.4 124.1,461.1 113.9,454.8 "/>
      <polygon className="st0 controlsvg" points="118.9,421.5 127.4,427.7 110.4,452.3 100.7,445.3 "/>
      <polygon className="st0 controlsvg" points="107.8,412.4 115.9,419.2 97.3,442.7 88.1,435 "/>
      <polygon className="st0 controlsvg" points="97.4,402.7 105,410 84.9,432.2 76.2,423.9 "/>
      <polygon className="st0 controlsvg" points="87.6,392.3 94.8,400 73.2,420.9 65.1,412 "/>
      <polygon className="st0 controlsvg" points="78.6,381.2 85.2,389.4 62.3,408.8 54.8,399.5 "/>
      <polygon className="st0 controlsvg" points="70.3,369.6 76.3,378.2 52.2,396 45.3,386.2 "/>
      <polygon className="st0 controlsvg" points="62.8,357.5 68.2,366.5 43,382.6 36.8,372.4 "/>
      <polygon className="st0 controlsvg" points="56.1,344.9 60.9,354.2 34.7,368.7 29.2,358 "/>
      <polygon className="st0 controlsvg" points="50.2,331.8 54.5,341.5 27.3,354.2 22.5,343.2 "/>
      <polygon className="st0 controlsvg" points="45.2,318.5 48.8,328.4 20.9,339.2 16.8,328 "/>
      <polygon className="st0 controlsvg" points="41.2,304.8 44.1,314.9 15.5,323.9 12.2,312.4 "/>
      <polygon className="st0 controlsvg" points="38,290.9 40.3,301.2 11.1,308.2 8.6,296.5 "/>
      <polygon className="st0 controlsvg" points="35.8,276.8 37.3,287.2 7.8,292.3 6,280.5 "/>
      <polygon className="st0 controlsvg" points="34.5,262.5 35.3,273 5.5,276.2 4.6,264.3 "/>
      <polygon className="st0 controlsvg" points="34.1,248.3 34.3,258.8 4.3,260 4.1,248.1 "/>
      <polygon className="st0 controlsvg" points="34.7,234 34.2,244.5 4.2,243.8 4.8,231.8 "/>
      <polygon className="st0 controlsvg" points="36.2,219.8 35,230.3 5.2,227.5 6.5,215.6 "/>
      <polygon className="st0 controlsvg" points="38.7,205.7 36.8,216.1 7.2,211.4 9.3,199.6 "/>
      <polygon className="st0 controlsvg" points="42.1,191.9 39.5,202.1 10.2,195.4 13.2,183.8 "/>
      <polygon className="st0 controlsvg" points="46.4,178.3 43.1,188.3 14.4,179.7 18.1,168.3 "/>
      <polygon className="st0 controlsvg" points="51.5,165 47.6,174.8 19.5,164.3 24,153.2 "/>
      <polygon className="st0 controlsvg" points="57.6,152 53,161.5 25.7,149.2 30.9,138.4 "/>
      <polygon className="st0 controlsvg" points="64.5,139.5 59.3,148.7 32.9,134.6 38.7,124.2 "/>
      <polygon className="st0 controlsvg" points="72.2,127.5 66.4,136.3 41,120.5 47.5,110.5 "/>
      <polygon className="st0 controlsvg" points="80.7,116 74.3,124.4 50,107 57.2,97.4 "/>
      <polygon className="st0 controlsvg" points="89.9,105.1 83,113.1 59.8,94.1 67.7,85 "/>
      <polygon className="st0 controlsvg" points="99.8,94.9 92.4,102.4 70.6,81.9 79,73.3 "/>
      <polygon className="st0 controlsvg" points="110.4,85.3 102.5,92.3 82.1,70.4 91,62.4 "/>
      <polygon className="st0 controlsvg" points="121.6,76.4 113.3,82.9 94.3,59.7 103.8,52.3 "/>
      <polygon className="st0 controlsvg" points="133.3,68.3 124.6,74.2 107.2,49.8 117.1,43.1 "/>
      <polygon className="st0 controlsvg" points="145.6,61 136.5,66.3 120.8,40.8 131.1,34.8 "/>
      <polygon className="st0 controlsvg" points="158.3,54.5 148.9,59.2 134.9,32.7 145.6,27.4 "/>
      <polygon className="st0 controlsvg" points="171.4,48.9 161.7,52.9 149.5,25.6 160.5,21 "/>
      <polygon className="st0 controlsvg" points="184.9,44.1 174.9,47.5 164.5,19.4 175.9,15.6 "/>
      <polygon className="st0 controlsvg" points="198.6,40.3 188.5,43 179.9,14.3 191.5,11.2 "/>
      <polygon className="st0 controlsvg" points="212.6,37.3 202.3,39.4 195.7,10.2 207.4,7.8 "/>
      <polygon className="st0 controlsvg" points="226.8,35.3 216.3,36.7 211.6,7.1 223.5,5.5 "/>
      <polygon className="st0 controlsvg" points="241,34.3 230.5,35 227.8,5.1 239.7,4.3 "/>
      <polygon className="st0 controlsvg" points="255.3,34.1 244.7,34.1 244,4.2 256,4.2 "/>

      <defs>
        <circle id="SVGID_1_" cx={250} cy={250} r={191.4} />
      </defs>

      

      <clipPath id="SVGID_00000181803064643676530290000016162448360346642563_">
        <use
          xlinkHref="#SVGID_1_"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      
      <g
        style={{
          clipPath:
            "url(#SVGID_00000181803064643676530290000016162448360346642563_)",
        }}
      >
        <image
          xlinkHref="/coachplay.png"
          width={721}
          height={722}
          style={{
            overflow: "visible",
            enableBackground: "new",
          }}
          transform="matrix(.53 0 0 .53 58.65 58.65)"
        />
      </g>

      
      <circle
        cx={250}
        cy={250}
        r={191.4}
        style={{
          fill: "none",
          stroke: "#d1d3d4",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <defs>
        <path
          id="SVGID_00000119117815569721119100000000199831435126951568_"
          d="M133.8 231.6h232.3v36.7H133.8z"
        />
      </defs>
      
    </svg>

  )
}
export default SvgComponent