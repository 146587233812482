/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 coaster.glb 
*/

import React, { useRef , useEffect} from 'react'
import { useGLTF } from '@react-three/drei'
import gsap from 'gsap';
import { useThree } from '@react-three/fiber';


export default function Model(props) {
  const { nodes, materials } = useGLTF('/3d/coaster.glb')
  const {targetPos , complete1} = props;
  const groupRef = useRef();
  const { viewport } = useThree();
  useEffect(()=>{
    if(groupRef.current){
      setTimeout(()=>{
        try{
          if(window.canplay){
            document.querySelector("#Coach-coffee-shop-batch-SFX").play()
          }
        }
        catch(e){
            console.log(e);
        }
      },1500)


      groupRef.current.traverse((obj) => {
        // check all materials
        if(obj.material) {
          
          obj.material.transparent = true
          // flip normals
          obj.material.side = 2
          // obj.alphaTest = 0.5
          obj.material.depthWrite = true
          obj.material.depthTest = true
          obj.material.needsUpdate = true
        }
      })

      if(complete1){
        let delay = 0;
        gsap.fromTo(groupRef.current.position, 
          {x : targetPos.x, y : targetPos.y, z : targetPos.z,
          },
          {
          x : 0, y : 0, z : 5, 
          duration : 2, 
          ease : "power2.inOut",
          delay : delay,
          onComplete : ()=>{
            window.canStart = true;
            let swipeup = document.getElementById("swipeup");
            let child = document.querySelector("#swipeup .child");
            child.style.pointerEvents = "auto";
            swipeup.style.opacity = 1;
          }
        })

        gsap.fromTo(groupRef.current.rotation, 
          {x : 0, y : 0, z : 0,
          },
          {
          x : Math.PI * 0.02, y : Math.PI * 2 + Math.PI * 0.1, z : 0, 
          duration : 2, 
          ease : "power2.inOut",
          delay : delay
        })

        gsap.fromTo(groupRef.current.scale, 
          {x : 0.1, y : 0.1, z : 0.1,
          },
          {
          x : 3.2, y : 3.2, z : 3.2, 
          duration : 2, 
          ease : "power2.inOut",
          delay : delay,
        })
      }
    }
  },[complete1])


  return (
    <group 
      ref={groupRef}
      dispose={null}
      name='myObject'
      position={[0,viewport.height * -1,0]}
    >
      <mesh geometry={nodes.Ms_Jo_Coaster.geometry} material={materials['Ms Jo Coaster']} scale={0.356} />
    </group>
  )
}

useGLTF.preload('/3d/coaster.glb')