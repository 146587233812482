//import { Routes, Route, BrowserRouter } from 'react-router-dom';
import '../App.css';
import { useState , useRef , useEffect } from 'react';
import Wraper from "./common/wraper";
import { Button } from './common';
import QrReader from "./common/QrReader";
import { useNavigate } from 'react-router-dom';
import {motion} from 'framer-motion';
const Index = () =>{
    // check if user already give permission
    const nav = useNavigate();
    const videoRef = useRef();
    const [src , setSrc] = useState(null);
    useEffect(()=>{
      try{
        let bgcontainer = document.getElementById("bg-video-container");
        bgcontainer.style.zIndex = "-2";
      }
      catch(e){
      }
    },[])

    useEffect(()=>{
      if(videoRef.current){
        // check divince if is ios use mov
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const videoFile = isIOS ? "/cube.mov" : "/cube.webm";
        const videoType = isIOS ? "video/quicktime" : "video/webm";
        videoRef.current.src = videoFile;
        videoRef.current.type = videoType;
      }
    },[])
  
    
    return(
      <>
        <div
          style={{
            width : "100svw",
            height : "100svh",
            position : "absolute",
            top : 0,
            zIndex : "3",
            pointerEvents : "none",
          }}
        >
          <video
            muted
            playsInline
            id="bg-video"
            style={{
              width : "100%",
              height : "100%",
              objectFit : "cover",
            }}
            ref={videoRef}
          >
          </video>
        </div>


        <div
            className='imgContainer-h'
            id='backIcon'
            style={{
                height : "5svh",
                margin : "auto",
                position : "absolute",
                right : "1rem",
                top : "1rem",
                zIndex : -2
            }}

            onClick = {(e)=>{
              e.preventDefault();
              e.stopPropagation();
              
              try{
                window.sayBye();

                let videoContainer = document.getElementById("bg-video-container");                
                videoContainer.style.opacity = 1;
                nav("/page3");  
              }
              catch(e){
                let videoContainer = document.getElementById("bg-video-container");
                videoContainer.style.opacity = 1;
                nav("/page3");  
              }
            }}
            >
            <img src='/2d/back.png' className='logo'/>
      </div>

        <div
          style={{
            position : "absolute",
            top : 0,
            left : 0,
            width : "100svw",
            height : "100svh",
            overflow : "hidden",
            zIndex : -2,
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              left : "50%",
              transform : "translateX(-50%)",
              top : "3.5%",
            }}
          >
              <div
                className='imgContainer-h'
                style={{
                    height : "14svh",
                    margin : "auto"
                }}
                >
                <img src='2d/logo.webp' className='logo'/>
              </div>

              <div className='block' style={{height : "1svh"}}/>
              <p 
                style={{
                  fontSize: "2svh",
                  margin: "2% 0 0 0",
                }}
              >
                Search and point camera to QR code
              </p>
          </div>
        <div
          style={{
            width : "100svw",
            height : "100svh",
            overflow : "hidden",
            position : "relative"
          }}
        >
          <QrReader/>
        </div>

        <div
          className='imgContainer-h'
          id='qrframe'
          style={{
            position : "absolute",
            top : "50%",
            left : "50%",
            transform : "translate(-50%,-50%)",
            width : "80svw",
            height : "80svw",
            maxHeight : "20rem",
            maxWidth : "20rem", 
          }}
        >
          <img src='2d/qrframe.png' className='logo'/>
        </div>


      </div>


        <div
            style={{
              height :  "14rem",
              zIndex : 4,
              position : "absolute",
              left : "50%",
              top : "83%",
              width : "14rem",
              transform : "translate(-50%,-50%)",
              transition : "all 0.5s",
              position : "relative",
              display : "flex",
              justifyContent : "center",
              alignItems : "center",
              pointerEvents : "none",
              opacity : 0,
            }}
            id='swipeup'
          >
            <div
              className='imgContainer-h'
              style={{
                height : "100%",
                zIndex : 4,
              }}
            >
              <img src='/2d/Coach_Box_C_logo.png' className='logo'/>
            </div>

            <motion.div
              className='imgContainer-h'
              style={{
                height : "60%",
                position : "absolute",
                left : "50%",
                top: "50%",
                transform : "translate(-50%,-50%)"
              }}

              initial={{
                height : "30%",
              }}
              animate={{
                height : ["50%","60%","50%"],
              }}
              transition={{
                repeat : Infinity,
                duration : 2.5,
              }}
            >
              <img src='/2d/Glow_Ring.png' className='logo'/>
            </motion.div>

            <div
              style={{
                position : "absolute",
                bottom : "50%",
                left : "50%",
                transform : "translate(-50%,50%)",
                height : "50%",
                width : "50%",
                pointerEvents : "none"
              }}
              className='child'
              onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                window.canStart = false;
                let _ = document.querySelector("#Swipe-Item-away-whoosh");
                if(window.canplay){
                  _.play().then(()=>{
                    window.sayBye();
                  }).catch(()=>{
                    window.sayBye();
                  });  
                }
                else{
                  window.sayBye();
                }

                let swipeup = document.getElementById("swipeup");
                swipeup.style.opacity = 0;
              }}
            />

            <h3
              style={{
                position : "absolute",
                top : "15%",
                color : "white",
                fontSize : "1.1rem",
              }}
            >
              Tap to collect
            </h3>
        </div>

      </>
    )   
}

export default Index