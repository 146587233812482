import { useFrame ,useLoader} from '@react-three/fiber'
import React, { Suspense, useState, useCallback, useEffect, useRef, useMemo } from 'react'
import * as THREE from 'three'
import { _vilocity , _gravity , _position , _initAcc} from './constant';


function Swarm({ count = 10 , type = 0 , targetPos , delay , texture}) {
    const mesh = useRef()
    const dummy = useMemo(() => new THREE.Object3D(), [])

    let textureLen = 9;
    useEffect(() => {
      if (mesh.current) {
        let index = type % textureLen;
        mesh.current.geometry.attributes.uv.array = new Float32Array([
          index / textureLen,
          1,
          (index + 1) / textureLen,
          1,
          index / textureLen,
          0,
          (index + 1) / textureLen,
          0,
        ]);  

        mesh.current.geometry.attributes.uv.needsUpdate = true;
      }
    }, []);
  
    const particles = useMemo(() => {
        const temp = []
        for (let i = 0; i < count; i++) {
          temp.push({
            position: targetPos.clone(),
            acc : _initAcc.clone(),
            velocity: new THREE.Vector3(0,0,0),
            gravity : _gravity.clone(),
            ranXacc : Math.random() * 0.12 - 0.06,
            ranYacc : Math.random() * 0.12 - 0.06,
            ranZacc : Math.random() * 0.12 - 0.06,
            t : Math.random() * 5,
            speed : Math.random() * 0.1 + 0.1,
            isTrigger : false,
            windForce : ()=>{
              let random = Math.random()
              if(random < 0.03){
                return new THREE.Vector3(
                  Math.random() * 0.11 - 0.055,
                  -Math.random() * 0.05 + 0.05,
                  Math.random() * 0.1 - 0.05
                )
              }
              else{
                return new THREE.Vector3(0,0,0)
              }
            }
          })
        }
        return temp
      }, [count])
    
    
    useFrame((state , delta)=>{
        if(mesh.current && delay){
            particles.forEach((particle, i) => {
                const { 
                  position ,
                  gravity,
                  velocity,
                  acc,
                  ranXacc,
                  ranYacc,
                  ranZacc,
                  isTrigger,
                  windForce,
                  t,
                  speed
                } = particle

                acc.add(gravity)
                velocity.add(acc)
                position.add(velocity)
                acc.multiply(new THREE.Vector3(0,0,0))


                if(isTrigger){
                  let wind = windForce()
                  acc.add(wind)  
                }
                // when vilocity is 0 apply force
                if(velocity.y < 0 && !isTrigger){
                  particle.acc = new THREE.Vector3(
                    ranXacc,
                    ranYacc,
                    ranZacc
                  ).multiplyScalar(1.8)

                  particle.isTrigger = true
                }
                
                if(isTrigger){
                  particle.t += delta * speed * 10
                  let s = Math.sin(particle.t)
                  dummy.rotation.set(0,0,s * 3)
                  dummy.scale.set(s,s,s)
                }

                // update matrix
                dummy.position.copy(position)
                dummy.updateMatrix()
                mesh.current.setMatrixAt(i, dummy.matrix)
            })
           
            mesh.current.instanceMatrix.needsUpdate = true
        }
    })

    useEffect(() => {
      window.applyForce = ()=>{
        if(mesh.current){
            particles.forEach((particle, i) => {
                const {
                  acc,
                } = particle

                acc.add(new THREE.Vector3(0,0.1,0))
            })          
        }
      }
    }, [])

  

    return (
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <planeGeometry args={[0.6, 0.6]}
          attach="geometry"
        />
        <meshBasicMaterial 
          map={texture} 
          side={THREE.DoubleSide}
          transparent={true}
          depthTest={false}
          depthWrite={false}
          opacity={delay ? 1 : 0}
        />
      </instancedMesh>
    )
}

function Index( {targetPos = new THREE.Vector3(0,0,0) , complete1 = false , setComplete1}){
  const texture = useLoader(THREE.TextureLoader, '/2d/2leaves.webp');

  useEffect(()=>{
    let video = document.getElementById("bg-video");
    if(video){
      video.play();
      video.onplaying = ()=>{
        
        setTimeout(()=>{
          setComplete1(true);
        },2800)
      }
    }
  },[])
  return (
    <>
      {
        new Array(8).fill(0).map((v,i)=>{
          return (
            <Swarm key={i} count={70} type={i} targetPos = {targetPos} delay = {complete1} texture = {texture}/>
          )
        })
      }
    </>
  )
}
export default Index;
