//import { Routes, Route, BrowserRouter } from 'react-router-dom';
import '../App.css';
import { useEffect, useState } from 'react';
import Wraper from "./common/wraper";
import { Button } from './common';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import sign from 'jwt-encode';
import { useSuperfan } from '../superfancontext';
import gsap from 'gsap';
function formatDate(date) {
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based.
  return `${day}/${month}`;
}

const Index = () =>{
  const [active , setActive] = useState(null);
  const [checked , setChecked] = useState([false , false , false]);
  const [onInstruction , setOnInstruction] = useState(true);
  const { collection , uid , sendEmailMgs} = useSuperfan();
  const navv = useNavigate();
  useEffect(()=>{
    if(checked[0] && checked[1] && checked[2]){
      
      axios.post(process.env.REACT_APP_ENDPOINT_SENDWHATSAPP, {
        phone : localStorage.getItem("emailOrMobile"),
        title1 : "Coach Ipoh",
        title2 : "COACH game",
        link : "https://coachipoh.com/",
        date : formatDate(new Date)
      }).then((res)=>{
        if(res.status === 200){
          localStorage.setItem("AlreadySend" , true)
        }
      })
  
      try{
        setTimeout(()=>{
          window.setStart();
        },1000)
        
        setTimeout(()=>{
          navv("/redeem")
        },3000)
      }
      catch(e){
        console.log(e);
      }
    }

    let alreayShow = localStorage.getItem("instruction");
    if(alreayShow){
      setOnInstruction(false);
    }
  },[checked])


  const nav = useNavigate();
    return(
      <Wraper
        key={3}
      >
        <div className='block' style={{
            height: "6svh"
        }}/>

        <div
          className='imgContainer-h'
          style={{
            height : "1.5rem",
            position : "absolute",
            right : "3svw",
            top : "3svw",
          }}

          onClick={()=>{
            setOnInstruction((prev)=>!prev);
          }}
        >
          <img src='/2d/info.webp'/>
        </div>

      <div
          className='imgContainer-h'
          style={{
            height : "3.5rem",
            margin : "auto"
          }}
        >
          <img src='/word2.webp'/>
        </div>


        <div className='block' style={{
          height: "2svh"
        }}/>

        <p>
          Hey there, camper! Find these camp essentials <br/> hidden at the Coach Play Ipoh store. Redeem a <br/>prize upon completion! While stocks last.
        </p>

        
        <div className='block' style={{
          height: "3.5svh"
        }}/>

        {
          ["/2d/1_1icon.webp" , "/2d/2_2icon.webp" , "/2d/3_3icon.webp"].map((item , index)=>{
            return (
              <div
                key = {index + "div"}
              >
                <ImgFrame src = {item}  active = {active} setActive = {setActive} index = {index} setChecked = {setChecked} checked = {checked}/>
                <div className='block' style={{height : "2svh"}}/>
              </div>
            )
          })
        }

        <div className='block' style={{
          height: "3svh"
        }}/>


        <motion.div
          animate={
            checked[0] && checked[1] && checked[2] ? 
            {
              scale : [1,1.06,1]
            }
            :
            null
        }

          transition={{
            repeat : Infinity,
            duration : 1.5
          }}
        >
          <Button 
            to = "/page4"
            _id = "play"
            onClick = {()=>{
              if(checked.every((item)=>item)){
                // nav("/page5")
                return;
              }
              nav("/page4")
            }}
            style = {{
              display : !checked.every((item)=>item) ? "block" : "none",
              margin : "auto"
            }}
          >
            {checked.every((item)=>item) ? "Redeem Prize" : "play"}
          </Button>
        </motion.div>

          {/* {
            onInstruction && <PopupInstuction setOnInstruction = {setOnInstruction}/>
          } */}
      </Wraper>
    )
}

const ImgFrame = (props)=>{
  const {src , setChecked , checked , setActive , index} = props
  useEffect(()=>{
    let item = localStorage.getItem("item" + (index + 1));
    if(item){
      setChecked((prev)=>{
        let temp = [...prev];
        temp[index] = true;
        return temp;
      })
    }
  },[])

  return (
    <div className={checked[index] ? "imgContainer-h" : "imgContainer-h"}
       style={{
         height : "16.8svh",
         width : "fit-content",
         margin : "auto",
         transition : "0.3s",
         filter : checked[index] ? "drop-shadow(0px 0px 2rem #e3c470)" : "grayscale(100%)",
        }}

       onClick={()=>{
        setActive(src)
       }}
     >
       <img src={src} className="icon"/>
    </div>
  )
}

const PopupInstuction = ({setOnInstruction})=>{
  const [current , setCurrent] = useState(0);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows : true,
    nextArrow : <div className='dotttt' style={{opacity : 0}}/>,
    afterChange: current => {setCurrent(current)}
  };

  return (
    <div
      style={{
        width : " 90svw",
        height : "80svh",
        backgroundColor : "#DEA42B",
        position : "fixed",
        left : "50%",
        top : "50%",
        transform : "translate(-50%,-50%)",
        border : "0.4rem solid #fff",
        borderRadius : "1rem",
      }}

      id='popupInstuction'

    >
      <div className='block' style={{
        height : "3.5%"
      }}/>
      <div
        className='imgContainer-h'
        style={{
          height : "11svh",
          margin : "auto"
        }}
      >
        <img src='/2d/logo.webp' className='logo'/>
      </div>
      <div className='block' style={{
        height : "1.5%"
      }}/>

      <Slider
        {...settings}
        style={{
          width : "100%"
        }}
      >
        <section
          className='sliderContainer'
        >
          <div className='block' style={{height : "2svh"}}/>
          <h4>
            Locate the clues to the<br/>
            hidden camp essentials.
          </h4>

          <div className='block' style={{height : "1.5svh"}}/>

          <p>
          Keep your eyes peeled for QR codes!
          </p>

          <div className='block' style={{height : "2svh"}}/>

          <div
            className='imgContainer-h'
            style={{
              height : "30svh",
              margin : "auto",
              
            }}
          >
            <img src='/2d/map3.webp'/>
          </div>
          <div className='block' style={{height : "2svh"}}/>
        </section>

        <section
          className='sliderContainer'
        >
          <div className='block' style={{height : "2svh"}}/>
          <h4>
            Spotted the clues?
          </h4>

          <div className='block' style={{height : "1.5svh"}}/>

          <p>
            Simply scan the QR codes!
          </p>

          <div className='block' style={{height : "2svh"}}/>

          <div
            className='imgContainer-h'
            style={{
              height : "30svh",
              margin : "auto",
              padding : "5% 0"
              
            }}
          >
            <img src='/mobile.webp'/>
          </div>
          <div className='block' style={{height : "2svh"}}/>
        </section>

      </Slider>

      <div className='block' style={{height : "6svh"}}/>

      
      <Button
        style={{
          margin : "auto"
        }}

        onClick={()=>{
          if(current === 1){
            localStorage.setItem("instruction" , true);
            setOnInstruction(false);  
          }
          else{
            let next = document.querySelector(".slick-next");
            if(next){
              next.click();
            }
          }
        }}
      >
        {
          current === 1 ? "READY!" : "NEXT"
        }
      </Button>


      {/* <div
        className='imgContainer-h'
        id='back'
        style={{
            height : "4svh",
            margin : "auto",
            position : "absolute",
            right : "3svw",
            top : "3svw"
        }}
          onClick={()=>{
            let back = document.querySelector(".slick-prev");
            if(back){
              back.click();
            }
          }}
        >
        <img src='/2d/back.png' className='logo'/>
      </div> */}

    </div>
  )
}
// 

export default Index;