
import { Button , Input , AnimateBox} from './common';
import Wraper from "./common/wraper"
import { useState , useMemo , useRef} from 'react';
import { useNavigate , useLocation } from 'react-router-dom';
import OTP from './component/otp';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import {Inner} from "./component/popupProgress"
import { useSuperfan } from '../index';
import gsap from 'gsap';
import GoogleImg from "./google_icon.png";
import Redeem from './component/redeem';
import QR from "./component/qrcode"

const Index = () => { 
  const [selectedId,setSelectedId] = useState(null);
  const location = useLocation();
  const nav = useNavigate();
  useMemo(() => {
    let pathName = location.pathname;
    switch (pathName){
      case "/":
        setSelectedId("pre-mobile");
        break;
      case "/pre-email":
        setSelectedId("pre-email");
        break;
      case "/pre-mobile":
        setSelectedId("pre-mobile");
        break;
      case "/otp":
        setSelectedId("otp");
        break;
      case "/collect-info":
        setSelectedId("collect-info");
        break;
      case "/popupProgress":
        setSelectedId("popupProgress");
        break;
      case "/redeem":
        setSelectedId("redeem");
        break;
      case "/qr":
        setSelectedId("qr");
        break;
      case "/collect-email":
        setSelectedId("collect-email");
        break;
      case "/collect-mobile":
        setSelectedId("collect-mobile");
        break;
      default:
        setSelectedId(null);
        break;
    }

  }, [location]);

  const [_isAnonymous , setIsAnonymous] = useState(true);
  
  return (
    <div>
        <motion.div
          style = {{
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            textAlign: "center",
            width: "100svw",
            height: "100svh",
            overflow: "hidden",
            position: "fixed",
            top: "0",
            zIndex: "99",
            opacity : _isAnonymous ? 1 : 0,
            pointerEvents : _isAnonymous ? "auto" : "none",
          }}
          id='pppContainer'
          className='superfanWraper'
          onClick={(e)=>{
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <motion.div 
              style={{
                transition: "0.8s ease-in-out", 
                borderRadius: "1rem 1rem 0 0",
                position: "absolute",
                left: "50%",
                transform: "translate(-50%,0)",            
                overflow: "scroll",
              }}

              // detect scroll down and up mobile
              onScroll={(e)=>{

              }}

              initial = {{
                bottom : "-100%",
              }}

              animate = {{
                bottom : "-1%",
              }}
            >
              <section
                style={{height : "70svh" , minHeight : "20rem" , width : "95svw" , position : "relative" , maxWidth : "30rem" , maxHeight : "40rem"}}
              >
                {
                  selectedId?.includes("pre-") && <Padding><Pre/></Padding>
                }
                {
                  selectedId === "otp" && <Padding><OTP/></Padding>}
                {
                  selectedId === "collect-info" && <Padding><CollectInfo/></Padding>}
                {
                  selectedId === "popupProgress" && <Padding><Inner/></Padding>
                }
                {
                  selectedId === "redeem" && <Redeem/>
                }
                {
                  selectedId === "collect-email" && <Padding><CollectEmail/></Padding>
                }
                {
                  selectedId === "collect-mobile" && <Padding><CollectMobile/></Padding>
                }
              </section>
          </motion.div>
        </motion.div>

        {selectedId === "qr" && <QR/>}
    </div>
  );
}

const Padding = ({
  children
})=>{
  return (
    <div
      style={{
        padding : "0 2.5rem",
        backgroundColor : "black",
        height : "100%",
      }}
    >
      {children}
    </div>
  )
}

const Pre = ()=>{ 
  const location = useLocation();
  const [ currentId , setCurrentId ] = useState(null);
  const {googleSignIn} = useSuperfan();
  const nav = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const mobileRegex = /^\+[0-9]{1,3}\s?[0-9]{1,14}$/;
  const { twillioOTP } = useSuperfan();
  const [sendingOTP, setSendingOTP] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);
  const valueRef = useRef(null);

  const handerClick = async()=> {
    let emailOrMobile = valueRef.current;
    setSendingOTP(true);
    if (currentId === "pre-email"){
      if (emailRegex.test(emailOrMobile)){
        try{
          await twillioOTP();
          setSendingOTP(false);
          nav("/otp");
        }
        catch(e){
          setSendingOTP(false);
          alert("Error")
        }
      }
      else{
        toast.error("Invalid Email");
        setSendingOTP(false);
      }
    }else{
      if (mobileRegex.test(emailOrMobile)){
        try{
          await twillioOTP();
          setSendingOTP(false);
          nav("/otp");
        }
        catch(e){
          setSendingOTP(false);
          alert("Error")
        }
      }
      else{
        toast.error("Invalid Mobile Number");
        setSendingOTP(false);
      }
    }
  }

  useMemo(() => {
    switch (location.pathname){
      case "/pre-email":
        localStorage.setItem("currentId","pre-email");
        setCurrentId("pre-email");
        break;
      case "/pre-mobile":
        localStorage.setItem("currentId","pre-mobile");
        setCurrentId("pre-mobile");
        break;
      default:
        localStorage.setItem("currentId","pre-mobile");
        setCurrentId("pre-mobile");
        break;
    }
  }, [location]);

  return (
    <motion.div
      initial = {{
        opacity: 0,
      }}
      animate = {{
        opacity: 1,
      }}

      exit={{
        opacity: 0,
      }}

      transition = {{
        delay : 1.2,
        duration: 0.8,
      }}

      style={{height : "100%"}}
    >
      <div className='block' style={{height : "2.2rem"}}/>
      <div
        className="imgContainer-h"
        style={{height : "1.2rem"}}
      >
        <img src = {"/2d/logo.png"}/>
      </div>

      <div className='block' style={{height : "0.9rem"}}/>

      <p style={{textAlign : "left"}}>Please sign-up or sign-in below.<br/>to enter experience.</p>

      <div className='block' style={{height : "2rem"}}/>
      {/*  */}
      
      <div
        style={{display : "flex" , justifyContent : "space-between"}}
      >
        <h5 
        >
          {currentId === "pre-mobile" ? "Mobile" : "Email"}
        </h5>   
        <h5
          style={{color : "rgb(71 76 80)"}}
          onClick={()=>{
            nav(currentId === "pre-mobile" ? "/pre-email" : "/pre-mobile")
          }}
        >{currentId === "pre-mobile" ? "Use Email" : "Use Mobile"}
        </h5>
      </div>

      <div className='block' style={{height : "1rem"}}/>

      {/*  */}
      <Input
        placeholder = {currentId === "pre-mobile" ? "+60 161231234" : "jason@example.com"}
        type = {currentId === "pre-mobile" ? "tel" : "email"}
        onChange = {(e)=>{
          localStorage.setItem("emailOrMobile",e.target.value);
          valueRef.current = e.target.value;

          // change mobile format
          const mobileRegex = /^\+[0-9]{1,3}\s?[0-9]{1,14}$/;
          if(e.target.value.length > 1 && e.target.value[0] !== "+" && currentId === "pre-mobile"){
              e.target.value = "+" + e.target.value;
          }
        }}
      />
      
      <div className='block' style={{height : "2rem"}}/>

      <Button 
        id = "2"
        style = {{
          backgroundColor: "#00BF63",
          color: "#000",
          position : "relative",
          overflow : "hidden",
        }}

        onClick = {async()=>{
          await handerClick();
        }}
      >
        {
          sendingOTP ? <AnimateBox/> : "Login/Sign-up"
        }
        {/* <AnimateBox/> */}
      </Button>

      <div className='block' style={{height : "1.2rem"}}/>

      <Button 
        id = "3"
        style = {{
          backgroundColor: "#141617",
          color: "white",
        }}

        onClick = {async()=>{
          googleSignIn(nav);
          setGoogleLogin(true);
        }}

      >
        <div
          style={{
            display : "flex",
            margin : "0 auto",
            width : "fit-content", 
            alignItems : "center",
          }}
        >
          {
            googleLogin && <AnimateBox/>
          }
          {
            !googleLogin && 
            <div
              style={{display : "flex" , alignItems : "center"}}
            >
              <div
                className='imgContainer-h'
                style={{height : "1.2rem"}}
              >
                <img src={GoogleImg}/>
              </div>
              <div style={{width : "0.6rem"}}/>
                Sign in with Google
            </div>
          }
        </div>
      </Button>


      <h6
        style={{
          position : "absolute",
          bottom : "2rem",
          left : "50%",
          transform : "translate(-50%,0)",
          color : "rgba(80,80,80,1)"
        }}

        onClick={()=>{
          // SUPERFANPrivacyPolicy.pdf
          window.open("/SUPERFANPrivacyPolicy.pdf","_blank");
        }}
      >Privacy Policy | Terms of use</h6>
    </motion.div>
  )
}

const CollectInfo = ()=>{ 
  const nav = useNavigate();
  const { twillioOTP , addDisplayName} = useSuperfan();
  const inputRef = useRef(null);

  
  return (
    <motion.div
      initial = {{
        opacity: 0,
      }}
      animate = {{
        opacity: 1,
      }}

      exit={{
        opacity: 0,
      }}

      transition = {{
        delay : 1.2,
        duration: 0.8,
      }}

      style={{height : "100%"}}
    >
      <div className='block' style={{height : "2.2rem"}}/>
      <div
        className="imgContainer-h"
        style={{height : "1.2rem"}}
      >
        <img src = {"/2d/logo.png"}/>
      </div>

      <div className='block' style={{height : "2rem"}}/>

      <p style={{textAlign : "left"}}>Please sign-up or sign-in below.</p>
      <div className='block' style={{height : "8%"}}/>
      {/*  */}
      <div
        style={{display : "flex" , justifyContent : "space-between"}}
      >  
        <h5
          style={{color : "white"}}
        >Enter name
        </h5>
      </div>

      <div className='block' style={{height : "1rem"}}/>

      {/*  */}
      <Input
        placeholder = {"Your Name"}
        onChange={(e)=>{
          inputRef.current = e.target.value;
        }}
      />
      
      <div className='block' style={{height : "2rem"}}/>

      <Button 
        id = "2"
        style = {{
          backgroundColor: "#fff",
          color: "#000",
        }}

        onClick = {()=>{
          addDisplayName(inputRef.current);
          nav("/page3");
        }}

      >Enter</Button>
    </motion.div>
  )
}

const CollectEmail = ()=>{ 
  const nav = useNavigate();
  const inputRef = useRef(null);

  
  return (
    <motion.div
      initial = {{
        opacity: 0,
      }}
      animate = {{
        opacity: 1,
      }}

      exit={{
        opacity: 0,
      }}

      transition = {{
        delay : 1.2,
        duration: 0.8,
      }}

      style={{height : "100%"}}
    >
      <div className='block' style={{height : "2.2rem"}}/>
      <div
        className="imgContainer-h"
        style={{height : "1.2rem"}}
      >
        <img src = {"/2d/logo.png"}/>
      </div>

      <div className='block' style={{height : "2rem"}}/>

      <p style={{textAlign : "left"}}>Please fill in your Email</p>
      <div className='block' style={{height : "8%"}}/>
      {/*  */}
      <div
        style={{display : "flex" , justifyContent : "space-between"}}
      >  
        <h5
          style={{color : "white"}}
        >Enter Email
        </h5>
      </div>

      <div className='block' style={{height : "1rem"}}/>

      {/*  */}
      <Input
        placeholder = {"Your Email"}
        onChange={(e)=>{
          inputRef.current = e.target.value;
        }}
      />
      
      <div className='block' style={{height : "2rem"}}/>

      <Button 
        id = "2"
        style = {{
          backgroundColor: "#fff",
          color: "#000",
        }}

        onClick = {()=>{
          nav("/redeem");
        }}

      >Enter</Button>
    </motion.div>
  )
}

const CollectMobile = ()=>{ 
  const nav = useNavigate();
  const inputRef = useRef(null);

  
  return (
    <motion.div
      initial = {{
        opacity: 0,
      }}
      animate = {{
        opacity: 1,
      }}

      exit={{
        opacity: 0,
      }}

      transition = {{
        delay : 1.2,
        duration: 0.8,
      }}

      style={{height : "100%"}}
    >
      <div className='block' style={{height : "2.2rem"}}/>
      <div
        className="imgContainer-h"
        style={{height : "1.2rem"}}
      >
        <img src = {"/2d/logo.png"}/>
      </div>

      <div className='block' style={{height : "2rem"}}/>

      <p style={{textAlign : "left"}}>Please fill in your Mobile number.</p>
      <div className='block' style={{height : "8%"}}/>
      {/*  */}
      <div
        style={{display : "flex" , justifyContent : "space-between"}}
      >  
        <h5
          style={{color : "white"}}
        >Enter phone number
        </h5>
      </div>

      <div className='block' style={{height : "1rem"}}/>

      {/*  */}
      <Input
        placeholder = {"+123 12312312"}
        onChange={(e)=>{
          inputRef.current = e.target.value;
        }}
      />
      
      <div className='block' style={{height : "2rem"}}/>

      <Button 
        id = "2"
        style = {{
          backgroundColor: "#fff",
          color: "#000",
        }}

        onClick = {()=>{
          nav("/redeem");
        }}

      >Enter</Button>
    </motion.div>
  )
}

export default Index;