import axios from "axios";

export async function getGeo(){
    try{
        const { ip } = await axios
        .get("https://api.ipify.org/?format=json")
        .then((res) => res.data);
    
        const { data } = await axios.get(`https://ipapi.co/${ip}/json/`);
    
        const {
            city,
            country,
            latitude,
            longitude,
            timezone,
            utc_offset,
        } = data;
    
        const geoData = {
            city,
            country,
            latitude,
            longitude,
            timezone,
            utc_offset,
        };
    
        return {
            geoData
        }    
    }
    catch{
        return {
            geoData : {}
        }
    }
}

