import {Canvas, useFrame} from "@react-three/fiber";
import { Environment , useProgress } from "@react-three/drei";
import Swarm from "./models/swarm";
import { useState , useRef , useEffect } from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import Beetle from "./models/Beetle_01";
import Signage from "./models/Coach_Signage_1_low";
import Coaster from "./models/Coaster";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { ConsoleView } from "react-device-detect";

export default function Index() {
    const canvasRef = useRef();

    return (
        <div
            style={{
                width : "100svw",
                height : "100svh",
                position : "absolute",
                top : 0,
                left : 0,
                zIndex : "-1",
            }}
            id="THREECONTAINER"
        >
            <Canvas
                camera={{
                    position : [0,0,10]
                }}

                style={{
                    position : "absolute",
                    top : 0,
                    zIndex : 1,
                }}

                ref={canvasRef}

                onCreated={({gl})=>{
                }}
            >
                <EffectComposer>
                    <Bloom
                        opacity={0.4}
                    />
                </EffectComposer>              

                <Index1/>
                <Index2/>
                <Index3/>
                <Controler canvasRef = {canvasRef}/>
                <Environment
                    files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/evening_road_01_2k.hdr"
                    intensity={0.5}
                    environmentIntensity = {1}
                    environmentRotation = {new THREE.Euler(0,0.3,0)}
                />        

                <Test/>
                <CheckPrelaod/>
            </Canvas>
        </div>
    )
}

const CheckPrelaod = ()=>{
    const { active, progress, errors, item, loaded, total } = useProgress();

    useFrame(()=>{
        if(progress === 100 && !window.isFullyLoadedMM){
            window.isFullyLoadedMM = true;
            let preload = document.getElementById("preload");
            preload.style.opacity = 0;
            preload.style.pointerEvents = "none";
            setTimeout(()=>{
                // remove preload
                preload.style.display = "none";
            },1000)
        }
    })

    return null
}

const Controler = ({canvasRef})=>{
    const { scene } = useThree();
    let lastX = 0;
    let lastY = 0;
    window.canStart = false;

    function sayBye(){
        let object = scene.getObjectByName("myObject");
        gsap.to(object.position, {x : 0, y : 10, z : 0, duration : 2.5, onComplete : ()=>{
            try{
                window?.closePlay1();
                window?.closePlay2();
                window?.closePlay3();    
            }
            catch(e){
                console.log(e);
            }
        }});

        gsap.to(object.rotation, {x : 0, y : Math.PI * 8, z : 0, duration : 2.5});
        gsap.to(object.scale, {x : 0.1, y : 0.1, z : 0.1, duration : 2.5});
    }

    const drag = (e)=>{
        let object = scene.getObjectByName("myObject");
        if(object && window.canStart){
            let x = e.touches[0].clientX;
            let y = e.touches[0].clientY;

            let speed = x - lastX;
            let speedY = y - lastY;

            if(lastX !== 0 && Math.abs(speed) < 14){
                object.rotation.y += speed * 0.01;
            }
            if(lastY !== 0 && Math.abs(speedY) < 14){
                object.rotation.x += (speedY) * 0.01;
            }

            lastY = y;
            lastX = x;            
        }
    }

    useEffect(()=>{

        window.sayBye = ()=>{
            sayBye();
        }

        if(canvasRef.current){
            // check already added event listener

            canvasRef.current.addEventListener("touchmove", drag)

            return ()=>{
                canvasRef.current.removeEventListener("touchmove", drag)
            }
        }
    },[])
    
    return null
}

// blue function
function blur(){
    let videoId = document.getElementById("video");
    if(videoId){
        videoId.style.filter = "blur(5px)";
    }
}

function unblur(){
    let videoId = document.getElementById("video");
    if(videoId){
        videoId.style.filter = "blur(0px)";
    }
}

const Index1 = ()=>{
    const [complete1 , setComplete1] = useState(false);
    const [isStart , setIsStart] = useState(false);
    let targetPos = new THREE.Vector3(0,0,0);
    const nav = useNavigate();

    useEffect(()=>{
        window.play1 = ()=>{
            window.isplaying = true;
            let THREECONTAINER = document.getElementById("THREECONTAINER");
            let qrframe = document.getElementById("qrframe");
            qrframe.style.opacity = 0;
            THREECONTAINER.style.zIndex = 1;
            setIsStart(true);
            blur();
        }

        window.closePlay1 = ()=>{
            let THREECONTAINER = document.getElementById("THREECONTAINER");
            let bgVideoContainer = document.getElementById("bg-video-container");
            bgVideoContainer.style.opacity = 1;
            // z-index: 1;
            THREECONTAINER.style.zIndex = -1;
            setIsStart(false);
            setComplete1(false);
            unblur();
            nav("/page3");
            window.isplaying = false;
        }
    },[])

    return (
        <>
            {
                isStart &&
                <>
                    <Swarm targetPos = {targetPos} complete1 = {complete1} setComplete1 = {setComplete1}/>
                    <Beetle targetPos = {targetPos} complete1 = {complete1}/>
                </>
            }
        </>
    )
}

const Index2 = ()=>{
    const [complete1 , setComplete1] = useState(false);
    const [isStart , setIsStart] = useState(false);
    let targetPos = new THREE.Vector3(0,0,0);
    const nav = useNavigate();

    useEffect(()=>{
        window.play2 = ()=>{
            window.isplaying = true;
            let THREECONTAINER = document.getElementById("THREECONTAINER");
            let qrframe = document.getElementById("qrframe");
            qrframe.style.opacity = 0;
            THREECONTAINER.style.zIndex = 1;

            setIsStart(true);
            blur();
        }

        window.closePlay2 = ()=>{
            window.isplaying = false;
            let THREECONTAINER = document.getElementById("THREECONTAINER");
            let bgVideoContainer = document.getElementById("bg-video-container");
            bgVideoContainer.style.opacity = 1;
            THREECONTAINER.style.zIndex = -1;
            setIsStart(false);
            setComplete1(false);
            unblur();
            nav("/page3");
        }
    },[])

    return (
        <>
            {
                isStart &&
                <>
                    <Swarm targetPos = {targetPos} complete1 = {complete1} setComplete1 = {setComplete1}/>
                    <Signage targetPos = {targetPos} complete1 = {complete1}/>
                </>
            }
        </>
    )
}

const Index3 = ()=>{
    const [complete1 , setComplete1] = useState(false);
    const [isStart , setIsStart] = useState(false);
    let targetPos = new THREE.Vector3(0,0,0);
    const nav = useNavigate();
    

    useEffect(()=>{
        window.play3 = ()=>{
            window.isplaying = true;
            let qrframe = document.getElementById("qrframe");
            qrframe.style.opacity = 0;
            let THREECONTAINER = document.getElementById("THREECONTAINER");
            THREECONTAINER.style.zIndex = 1;
            setIsStart(true);
            blur();
        }

        window.closePlay3 = ()=>{
            window.isplaying = false;
            let THREECONTAINER = document.getElementById("THREECONTAINER");
            let bgVideoContainer = document.getElementById("bg-video-container");
            bgVideoContainer.style.opacity = 1;
            THREECONTAINER.style.zIndex = -1;
            setIsStart(false);
            setComplete1(false);
            unblur();
            nav("/page3");
        }
    },[])

    return (
        <>
            {
                isStart &&
                <>
                    <Swarm targetPos = {targetPos} complete1 = {complete1} setComplete1 = {setComplete1}/>
                    <Coaster targetPos = {targetPos} complete1 = {complete1}/>
                </>
            }
        </>
    )
}


const Test = ()=>{
    let targetPos = new THREE.Vector3(0,0,0);
    const [start , setStart] = useState(false);
    useEffect(()=>{
        window.setStart = ()=>{
            if(!window.isFullyLoadedMM) return;
            try{
                if(window.canplay){
                    document.querySelector("#End-Redeem-prize-SFX").play();
                }
            }
            catch(e){
                console.log(e);
            }
            let THREECONTAINER = document.getElementById("THREECONTAINER");
            THREECONTAINER.style.opacity = 1;
            THREECONTAINER.style.zIndex = -1;

            setStart(true);

            setTimeout(()=>{
                setStart(false);
            },5000)
        }
    },[])

    return (
        <>
            {
                start && 
                <Swarm targetPos = {targetPos} complete1 = {start}/>
            }
        </>
    )
}