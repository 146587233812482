/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 beetle_01.glb 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF , Sparkles } from '@react-three/drei'
import gsap from 'gsap';
import { useFrame, useThree } from '@react-three/fiber';

export default function Model(props) {
  const {targetPos , complete1} = props;
  const { viewport } = useThree();
  const { nodes, materials } = useGLTF('/3d/SP_Beetle_05.glb')
  const groupRef = useRef();

  useEffect(()=>{
    if(groupRef.current){
      groupRef.current.traverse((obj) => {
        // check all materials
        if(obj.material) {
          
          obj.material.transparent = true
          // flip normals
          obj.material.side = 2
          // obj.alphaTest = 0.5
          obj.material.depthWrite = true
          obj.material.depthTest = true
          obj.material.needsUpdate = true
        }
      })

      if(complete1){
        let delay = 0;
        setTimeout(()=>{
          try{
            if(window.canplay){
              document.querySelector("#Car-Engine-Horn").play();
            }
          }
          catch(e){
              console.log(e);
          }

        },600)
        gsap.fromTo(groupRef.current.position, 
          {x : targetPos.x, y : targetPos.y, z : targetPos.z,
          },
          {
          x : -0.3, y : 0.5, z : 5, 
          duration : 2, 
          ease : "power2.inOut",
          delay : delay,

          onComplete : ()=>{
            window.canStart = true;
            let swipeup = document.getElementById("swipeup");
            let child = document.querySelector("#swipeup .child");
            child.style.pointerEvents = "auto";
            swipeup.style.opacity = 1;
          }
        })

        gsap.fromTo(groupRef.current.rotation, 
          {x : 0, y : 0, z : 0,
          },
          {
          x : Math.PI * 0.02, y : Math.PI * 2 + Math.PI * 0.1, z : 0, 
          duration : 1.8, 
          ease : "power2.inOut",
          delay : delay,
        })

        gsap.fromTo(groupRef.current.scale, 
          {x : 0.1, y : 0.1, z : 0.1,
          },
          {
          x : 1.1, y : 1.1, z : 1.1, 
          duration : 2, 
          ease : "power2.inOut",
          delay : delay,
        })  
      }
    }
  },[complete1])



  return (
    <group {...props} 
      dispose={null} 
      ref={groupRef}
      position={[0,viewport.height * -1,0]}
      name='myObject'
    >
      <group scale={0.007}
        rotation={[0, Math.PI , 0]}
        position={[0, -1, 0]}
      >
        <mesh geometry={nodes.rack.geometry} material={materials['RE lambert1']} />
        <mesh geometry={nodes.Door_L_1_0_001.geometry} material={materials['RE material']} />
        <mesh geometry={nodes.Door_R_1_0_001.geometry} material={materials['RE material']} />
        <mesh geometry={nodes.Front_1_0_001.geometry} material={materials['RE material']} />
        <mesh geometry={nodes.Main_1_0_001.geometry} material={materials['RE material']} />
        <mesh geometry={nodes.Rear_1_0_001.geometry} material={materials['RE material']} />
        <mesh geometry={nodes.Door_L_2_0_001.geometry} material={materials['RE material_1']} />
        <mesh geometry={nodes.Door_R_2_0_001.geometry} material={materials['RE material_1']} />
        <mesh geometry={nodes.Front_2_0_001.geometry} material={materials['RE material_1']} />
        <mesh geometry={nodes.Main_2_0_001.geometry} material={materials['RE material_1']} />
        <mesh geometry={nodes.Wheel_B_L_2_0_001.geometry} material={materials['RE material_1']} />
        <mesh geometry={nodes.Wheel_B_R_2_0_001.geometry} material={materials['RE material_1']} />
        <mesh geometry={nodes.Wheel_F_L_2_0_001.geometry} material={materials['RE material_1']} />
        <mesh geometry={nodes.Wheel_F_R_2_0_002.geometry} material={materials['RE material_1']} />
        <mesh geometry={nodes.Door_L_3_0_001.geometry} material={materials['RE material_2']} />
        <mesh geometry={nodes.Door_R_3_0_002.geometry} material={materials['RE material_2']} />
        <mesh geometry={nodes.Main_3_0_001.geometry} material={materials['RE material_2']} />
        <mesh geometry={nodes.Door_L_4_0_001.geometry} material={materials['RE material_3']} />
        <mesh geometry={nodes.Door_R_4_0_001.geometry} material={materials['RE material_3']} />
        <mesh geometry={nodes.Front_4_0_001.geometry} material={materials['RE material_3']} />
        <mesh geometry={nodes.Rear_4_0_002.geometry} material={materials['RE material_3']} />
      </group>
    </group>
  )
}


useGLTF.preload('/3d/SP_Beetle_05.glb');